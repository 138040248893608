// RequestDeletionSection.js

import React, { useState } from 'react';
import './support.css';
import '../../styles/global.css';
import ConfirmationAlert from '../alert/ConfirmationAlert'

const RequestDeletionSection = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
        // Add logic for handling email submission
    };

    const handleCancel = () => {
        // Close the confirmation modal
        setShowConfirmation(false);
    };

    const handleConfirm = () => {
        // Add logic for handling email submission (send deletion request)
        // Close the confirmation modal
        setShowConfirmation(false);
    };

    return (
        <section className='delete-account-container column'>
            <div className='delete-text-content'>
                <h2>Account Deletion</h2>
                <p>Ready to part ways? Enter your email address below and click 'Submit Request' to initiate the account deletion process. We'll keep you updated via this email. Once confirmed, your account will be permanently removed.<br></br><br></br>If you used the hidden email feature with Apple Sign-In, ensure secure closure by submitting your deletion request through the JustTrade app.<br></br><br></br>We'll handle the rest. Farewell and thank you for being with us!</p>
                <p className='deletion-hint'><i>*Please note that this action is irreversible, and all associated data will be permanently removed. Ensure that you have backed up any essential information before proceeding</i></p>
                <form onSubmit={handleEmailSubmit} className='form'>
                    <input type="email" id="email" name="email" required className='flat-input' placeholder="Enter your valid email" />
                    <button type="submit" className="primary-button">
                        Send Request
                    </button>
                </form>
            </div>
            <div className='column'></div>

            {showConfirmation && (
                <ConfirmationAlert onClose={handleCancel} onConfirm={handleConfirm} />
            )}
        </section>
    );
};

export default RequestDeletionSection;
