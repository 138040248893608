import React from 'react';
import Header from '../components/header/Header';
import '../styles/global.css';
import Banner from '../components/banner/Banner';
import Footer from '../components/footer/Footer';

const LandingPage = () => {
  return (
    <div class="page">
      <Banner />
    </div>
  );
};

export default LandingPage;