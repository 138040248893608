// Footer.js

import React from 'react';
import './footer.css';

const Footer = () => {
  // Sample quick links
  const quickLinks = [
    { label: 'Privacy Policy', link: '/privacy-policy' },
    { label: 'Terms and Conditions', link: '/terms-and-conditions' },
    { label: 'Support', link: '/support' },
  ];

  // Sample menu items
  const menuItems = [
    { label: 'Home', link: '/' },
    { label: 'Features', link: '/features' },
    { label: 'About', link: '/about' },
    { label: 'Contact', link: '/contact' },
  ];

  return (
    <footer className="footer-container">
      <div className="quick-links">
        {quickLinks.map((link, index) => (
          <a key={index} href={link.link} className="quick-link">
            {link.label}
          </a>
        ))}
      </div>

      <div className="menu-items">
        {menuItems.map((menuItem, index) => (
          <a key={index} href={menuItem.link} className="menu-item">
            {menuItem.label}
          </a>
        ))}
      </div>

      <p>&copy; 2023 Chellobee Technologies LLP. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
