// PrivacyPolicy.js

import React from 'react';
import '../components/privacy-policy/privacy-policy.css';

const TermsConditions = () => {
    return (
        <div className='privacy-policy-container'>
            <div className='privacy-policy-content'>
                <h1>Terms and Conditions</h1>
                <p><i>Last Updated: Dec 10, 2023</i></p>

                <p><strong>Introduction</strong></p>

                <p>Welcome to <b>Justrade</b>, an online platform for buying and selling used items. Before you proceed, please read the following terms and conditions carefully. By accessing or using this mobile application, you are acknowledging that you have read, understood, and agree to be bound by these terms.</p>

                <p><strong>1. Acceptance of Terms:</strong></p>

                <p>By accessing or using our mobile application, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using the application.</p>

                <p><strong>2. Services:</strong></p>

                <p><b>Justrade</b> provides a platform where users can list, sell, or donate used items. This platform does not facilitate any financial transactions; all monetary exchanges occur directly between the seller and the buyer.</p>

                <p><strong>3. User Responsibilities:</strong></p>

                <ul>
                    <li>Users are responsible for ensuring the accuracy and completeness of the information provided in their listings.</li><br></br>
                    <li>Users must not engage in any illegal, fraudulent, or deceptive activities on the platform.</li><br></br>
                    <li>It is the responsibility of users to arrange and conduct transactions in a safe, respectful, and lawful manner.</li><br></br>
                    <li><strong>Age Restriction:</strong> Users must be at least 18 years old to use this platform. By using the application, you confirm that you meet this age requirement.</li>
                </ul>

                <p><strong>4. Listing Items:</strong></p>

                <ul>
                    <li>Sellers are obligated to provide accurate, truthful, and detailed information about the items they list.</li><br></br>
                    <li>All listed items must adhere to our community guidelines and comply with local laws.</li><br></br>
                    <li>Prohibited items include, but are not limited to, weapons, illegal substances, counterfeit goods, stolen property, hazardous materials, food items, and items that promote hate speech or discrimination.</li>
                </ul>

                <p><strong>5. Communication and Transactions:</strong></p>

                <p>The mobile application facilitates communication between buyers and sellers. Users are encouraged to communicate through the application to maintain a record of conversations. All transactions, including meeting arrangements, negotiations, and item inspections, are the sole responsibility of the users involved.</p>

                <p><strong>6. Safety and Security:</strong></p>

                <ul>
                    <li>Users are strongly advised to conduct transactions in public places with high visibility.</li><br></br>
                    <li>It is the responsibility of users to exercise caution and take necessary precautions to ensure their personal safety during meetings.</li><br></br>
                    <li>In the event of suspicious activity or concerns, users are urged to contact local authorities.</li>
                </ul>

                <p><strong>7. Disputes:</strong></p>

                <ul>
                    <li>Any disputes arising from transactions conducted through the mobile application are solely the responsibility of the involved parties.</li><br></br>
                    <li>The application is not liable for any disputes, damages, or losses incurred during or as a result of transactions.</li><br></br>
                    <li>Users are encouraged to attempt to resolve disputes amicably through communication.</li>
                </ul>

                <p><strong>8. Termination of Account:</strong></p>

                <p><b>Justrade</b> reserves the right to terminate or suspend user accounts for any violations of these terms and conditions. Termination may occur if there is evidence of fraudulent activity, repeated policy violations, or misuse of the platform.</p>

                <p><strong>9. Prohibited Items and Activities:</strong></p>

                <ul>
                    <li>The following items and activities are strictly prohibited on our platform:</li>
                    <li>Weapons and firearms</li>
                    <li>Illegal substances and drugs</li>
                    <li>Stolen property</li>
                    <li>Counterfeit goods</li>
                    <li>Hazardous materials</li>
                    <li>Food items</li>
                    <li>Any item that promotes hate speech or discrimination</li>
                    <li>Any attempt to list or transact with prohibited items may result in the immediate termination of the user's account.</li>
                </ul>

                <p><strong>10. User Conduct:</strong></p>

                <p>Users are expected to conduct themselves in a respectful, ethical, and lawful manner. Any form of harassment, discrimination, or abusive behavior towards other users will not be tolerated.</p>

                <p><strong>11. Modifications:</strong></p>

                <p><b>Justrade</b> reserves the right to modify or update these terms and conditions at any time. Users will be notified of significant changes through the application or via email.</p>

                <p><strong>12. Privacy:</strong></p>

                <p>Users' privacy is a top priority. Please refer to our comprehensive privacy policy for detailed information on how we collect, use, and disclose personal information.</p>

                <p><strong>13. Governing Law:</strong></p>

                <p>These terms and conditions are governed by the laws of India. Any legal disputes arising from the use of our mobile application shall be resolved in the appropriate courts of India.</p>

                <p><strong>14. Disclaimer of Liability:</strong></p>

                <p><b>Justrade</b> is not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from the use of our platform. The platform is provided "as is" without any warranties, express or implied.</p>

                <p><strong>15. Contact Information:</strong></p>

                <p>For any questions or concerns regarding these terms and conditions, please contact us at support-justrade@chellobee.com.</p>

                <p>Thank you for choosing <b>Justrade</b>. We wish you a secure and positive experience on our platform.</p>
            </div>
        </div>
    );
};

export default TermsConditions;
