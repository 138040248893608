// Header.js
import React, { useState } from 'react';
import './header.css';
import Sidebar from '../sidebar/Sidebar';
import BrandImage from '../../images/brand-icon.png';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const handleNavLinkClick = (to) => {
    navigate(to);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <div className="header">
      <div className="container">
        <div className="brand-name">
          <div><img src={BrandImage}></img></div>
          <div>Justrade</div>
        </div>
        <div className="menu">
          {/* Render menu items for all screens */}
          <nav className="navbar">
            <NavLink to="/" className="navbar-button" onClick={() => handleNavLinkClick('/')}>Home</NavLink>
            <button className="navbar-button">Features</button>
            <button className="navbar-button">About</button>
            <button className="navbar-button">Contact</button>
            {/* Add the Download App button */}
            <button className="navbar-button download-button" onClick={() => console.log('Download App clicked')}>
              Download App
            </button>
          </nav>
          {/* Render hamburger icon only for smaller screens */}
          <div className="hamburger-icon" onClick={toggleSidebar}>
            &#9776;
          </div>
        </div>
      </div>

      {/* Include the Sidebar component */}
      <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} />
    </div>
  );
};

export default Header;
