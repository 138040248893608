// PrivacyPolicy.js

import React from 'react';
import '../components/privacy-policy/privacy-policy.css';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy-container'>
            <div className='privacy-policy-content'>
                <h1>Privacy Policy</h1>
                <p><i>Last Updated: Dec 10, 2023</i></p>

                <p>Welcome to Justrade's Privacy Policy. This document outlines how we collect, use, and protect your personal information, including access to your camera, device ID, location, files, and notifications. By using our mobile application, you agree to the terms of this Privacy Policy.</p>

                <strong>1. Information We Collect</strong>
                <ul>
                    <li><strong>Personal Information:</strong>
                        <ul>
                            <li>When you register an account, we may collect personal information such as your name, email address, phone number, date of birth, and contact details.</li>
                            <li>Profile information you voluntarily provide, such as profile pictures and additional contact details.</li>
                        </ul>
                    </li>

                    <li><strong>Listings and Transactions:</strong>
                        <ul>
                            <li>Information related to items you list, sell, or donate on Justrade, including descriptions, prices, and transaction details.</li>
                        </ul>
                    </li>

                    <li><strong>Communication:</strong>
                        <ul>
                            <li>Records of your communications with other users through the Justrade application, including messages and transaction-related discussions.</li>
                        </ul>
                    </li>

                    <li><strong>Device Information:</strong>
                        <ul>
                            <li><strong>Device ID:</strong> We may collect your device's unique identifier for analytical purposes and to improve the Justrade application.</li>
                        </ul>
                    </li>

                    <li><strong>Location Information:</strong>
                        <ul>
                            <li><strong>Location Access:</strong> We may request access to your device's location to provide location-based services, such as showing nearby items and improving user experience.</li>
                        </ul>
                    </li>

                    <li><strong>Device Permissions:</strong>
                        <ul>
                            <li><strong>Camera Access:</strong> We may request access to your device's camera for functionalities such as taking pictures to set profile pictures or upload item listing pictures.</li>
                            <li><strong>File Access:</strong> Access to your device's files may be requested for uploading images or attaching files related to listings or communications.</li>
                            <li><strong>Notification Access:</strong> Permission to send notifications to your device for updates, messages, and important announcements.</li>
                        </ul>
                    </li>
                </ul>

                <strong>2. How We Use Your Information</strong>
                <ul>
                    <li><strong>Platform Functionality:</strong>
                        <ul>
                            <li>To provide the services offered on our platform, including facilitating transactions and communication between users.</li>
                            <li>Enhancing user experience through personalized features based on your preferences and interactions.</li>
                        </ul>
                    </li>

                    <li><strong>Security:</strong>
                        <ul>
                            <li>Detecting and preventing fraudulent activities to ensure the security of the Justrade platform and protect users.</li>
                        </ul>
                    </li>
                </ul>

                <strong>3. Information Sharing</strong>
                <ul>
                    <li><strong>Other Users:</strong> Information necessary to facilitate transactions and communication between users.</li>
                    <li><strong>Legal Authorities:</strong> In response to legal requests or to comply with applicable laws and regulations.</li>
                    <li><strong>Third-Party Ads Integration:</strong> We may integrate third-party ads on the Justrade platform. These ads may collect certain information to provide personalized and relevant advertisements.</li>
                </ul>

                <strong>4. Children's Policy</strong>
                <p>Justrade's services are intended for individuals aged 18 and above. We do not knowingly collect personal information from individuals under the age of 18. If you are under 18, please do not use the Justrade platform or provide any personal information. If you believe that we may have inadvertently collected information from someone under 18, please contact us, and we will take appropriate steps to delete such information.</p>

                <strong>5. Your Choices</strong>
                <p>You can manage your account settings and preferences within the Justrade application, including permissions for camera, location, files, and notifications. You may also contact us to update or delete your information.</p>

                <strong>6. Account Deletion</strong>
                <p>If you request the deletion of your Justrade account, we will process the request within 60 days. However, certain details such as reviews and transaction history may be retained for the purpose of maintaining the integrity of the platform.</p>

                <strong>7. Data Retention Policy</strong>
                <p>We retain your personal information for a period of 60 days from the date of account deletion. After this retention period, your personal information will be securely deleted or anonymized.</p>

                <strong>8. Security</strong>
                <p>We implement security measures to protect your information on Justrade. However, no method of transmission over the internet is entirely secure. We encourage you to use strong passwords and to notify us of any suspicious activities.</p>

                <strong>9. Changes to this Privacy Policy</strong>
                <p>We may update this Privacy Policy for Justrade to reflect changes in our practices. We will notify you of any significant changes through the application or via email.</p>

                <strong>10. Contact Information</strong>
                <p>For any questions or concerns regarding this Privacy Policy for Justrade, please contact us at support-justrade@chellobee.com.</p>

                <p>Thank you for using Justrade. We are committed to protecting your privacy and providing a secure and positive experience on our platform.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
