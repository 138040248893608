import React from 'react';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import LandingPage from './pages/LandingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsConditionsPage from './pages/TermsConditionsPage';
import SupportPage from './pages/SupportPage';
import './styles/global.css';
import { Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <div class="page">
      <Header />
        <Routes>
          <Route path="/support" element={<SupportPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsConditionsPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      <Footer />
    </div>
  );
};

export default App;