// ConfirmationAlert.js

import React from 'react';
import './ConfirmationAlert.css';

const ConfirmationAlert = ({ onClose, onConfirm }) => {
    return (
        <div className="confirmation-modal">
            <div className="confirmation-content">
                <p className='alert-title'>Delete Confirmation?</p>
                <p>Are you sure you want to delete your account? This action is irreversible and will permanently remove all your account data. Please confirm to proceed.</p>
                <div className='action-button-container'>
                    <button className="accent-button" onClick={onClose}>
                        Cancel
                    </button>
                    &nbsp;
                    <button className="primary-button" onClick={onConfirm}>
                        Confirm Deletion
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationAlert;
