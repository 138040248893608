// Sidebar.js
import React from 'react';
import './sidebar.css';

const Sidebar = ({ isOpen, onClose }) => {
  const handleItemClick = () => {
    // Perform any additional actions when a menu item is clicked
    // For example, navigate to a different page or execute some functionality
    // ...

    // Close the sidebar
    onClose();
  };

  const sidebarStyle = {
    right: isOpen ? 0 : '-100%', // Adjust the right position based on the isOpen prop
  };

  return (
    <div className="sidebar" style={sidebarStyle}>
      {/* Close icon */}
      <div className="close-icon" onClick={onClose}>
        &#10006;
      </div>
      
      <button className="sidebar-button" onClick={handleItemClick}>
        Home
      </button>
      <button className="sidebar-button" onClick={handleItemClick}>
        Features
      </button>
      <button className="sidebar-button" onClick={handleItemClick}>
        About
      </button>
      <button className="sidebar-button" onClick={handleItemClick}>
        Contact
      </button>

      {/* Download App button */}
      <a
        href="#"
        className="sidebar-button sidebar-download-button"
        onClick={handleItemClick} /* Handle click if needed */
      >
        Download App
      </a>
    </div>
  );
};

export default Sidebar;
