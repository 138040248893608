// SupportPage.js

import React from 'react';
import SupportSection from '../components/support/SupportSection';
import RequestDeletionSection from '../components/support/RequestDeletionSection';

const SupportPage = () => {
    return (
        <div className='support-page-container'>
            <div className='support-page-content'>
                <SupportSection />
                <RequestDeletionSection />
            </div>
        </div>
    );
};

export default SupportPage;
