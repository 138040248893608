// SupportSection.js

import React from 'react';
import './support.css'; // Import your CSS file
import SupportImage from '../../images/support-image.png';

const SupportSection = () => {
    return (
        <section className="support-section">
            <div className="column">
                <img src={SupportImage} alt="Support" className="support-image" />
            </div>
            <div className="column">
                <div className="text-content">
                    <h2>Reach out for assistance – we're here to help and support!</h2>
                    <p>
                        Questions or concerns? Easily reach out to us using the 'Write to Us' form within the app. Your feedback
                        matters, and our support team is ready to assist you promptly. For additional support, you can also email us at{' '}
                        <u>support-justrade@chellobee.com</u>.
                    </p>
                    <br></br>
                    <a href="mailto:support-justrade@chellobee.com" className="primary-button">
                        Send Email
                    </a>
                </div>
            </div>
        </section>
    );
};

export default SupportSection;
