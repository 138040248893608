// Banner.js
import React from 'react';
import './banner.css';
import GooglePlayBadge from '../../images/google-play-badge.svg';
import AppStoreBadge from '../../images/app-store-badge.svg';
import BannerImage from '../../images/banner-image-1.svg';
import LaunchSoonImage from '../../images/lauching-soon-image.svg';
import BgImage from '../../images/bg.svg';

var BgStyle = {
  width: "100%",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom',
  backgroundImage: `url(${BgImage})`,
};

const Banner = () => {
  return (
    <div class="banner" style={BgStyle}>
      <div className="banner-container">
        <div className="banner-content">
          <div className="banner-text">
            <h1 className="banner-title">Sell, buy & Donate items. Your trusted go-to marketplace!</h1>
            <div className="banner-description-group">
              <p className="banner-description">1. Buy, sell, and donate effortlessly</p>
              <p className="banner-description">2. Direct connection with sellers, buyers, and charitable causes</p>
              <p className="banner-description">3. Simplify your online marketplace experience</p>
              <p className="banner-description">4. Download now for seamless transactions and impactful giving!</p>
            </div>
          </div>
          <div className="store-badges">
            <a href="link-to-google-play" target="_blank" rel="noopener noreferrer">
              <img src={GooglePlayBadge} alt="Google Play" />
            </a>
            <a href="link-to-app-store" target="_blank" rel="noopener noreferrer">
              <img src={AppStoreBadge} alt="App Store" />
            </a>
          </div>
        </div>
        <div className="banner-image">
          <img src={BannerImage} alt="Marketplace" />
          
        </div>
      </div>
    </div>
  );
};

export default Banner;
